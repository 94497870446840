<template>
<!-- eslint-disable -->
	<div>
		<h1 class="text-center my-6">通知内容</h1>
		<v-row class="ml-4">
			<v-col cols="9">
				<DatePicker @changeDate="setDate"/>
			</v-col>
		</v-row>
		<v-textarea
			outlined
			name="notification"
			label="通知内容"
			v-model="content"
			height="300px"
			style="width:300px; margin: auto;"
			v-on:keydown.enter="enterDown()"
			v-bind:rules="upRules"
        ></v-textarea>
		<v-row style="max-width: 100% !important; margin: 0;">
			<v-btn
				class="mx-auto mt-4"
				:loading="loading"
				:disabled="loading"
				color="secondary"
				@click="upload()"
				style="width:150px;"
			>
				UPLOAD
			</v-btn>
		</v-row>


	</div>
</template>

<script>
/* eslint-disable */
import * as api from '@/api.js'
import DatePicker from '@/components/DatePicker.vue'
export default {
	name: 'Notification',
	components: { DatePicker },
    data () {
      return {
        loader: null,
        loading: false,
		menu: false,
		content: '',
		upRules: [text => text.length <= 595 || '最大文字数は595文字です'],
		date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      }
    },
	methods: {
		load(){
			this.content = ''
		},
		setDate(date) {
			this.date = date
		},
		upload(){
			this.loader = 'loading'
			api.notification.upload({date: this.date, content: this.content}).then((res) => {
                switch(res.data.status) {
                    case 200:
						this.pop('', 'アップロードが成功しました！')
                        break
                    case 500:
						this.pop('アップロードが失敗しました', '※空の投稿はできません')
                        break
                }
			}).catch()
			this.content = ''
		},

		pop(title, message) {
			this.$store.dispatch('setDialog', { title: title, message: message });
		},

		enterDown() {
			return this.content.replace(/\\n/g,'\n')
		}
	},
	created() {
		this.load()
	},
    watch: {
      loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
      },
    },
  }
</script>
<style>
.gradation{
    background: linear-gradient(to left top, rgba(204, 204, 204, 0.3) 15%, white 5%, white 85%, rgba(204, 204, 204, 0.3) 10%);
}
.v-dialog > .v-card > .v-card__actions {
	margin: auto;
	max-width: 300px;
}
</style>